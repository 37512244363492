exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-business-digitalisation-enterprise-commercial-solution-tsx": () => import("./../../../src/pages/business-digitalisation/enterprise-commercial-solution.tsx" /* webpackChunkName: "component---src-pages-business-digitalisation-enterprise-commercial-solution-tsx" */),
  "component---src-pages-business-digitalisation-smart-building-management-tsx": () => import("./../../../src/pages/business-digitalisation/smart-building-management.tsx" /* webpackChunkName: "component---src-pages-business-digitalisation-smart-building-management-tsx" */),
  "component---src-pages-business-digitalisation-smart-industry-tsx": () => import("./../../../src/pages/business-digitalisation/smart-industry.tsx" /* webpackChunkName: "component---src-pages-business-digitalisation-smart-industry-tsx" */),
  "component---src-pages-business-digitalisation-tsx": () => import("./../../../src/pages/business-digitalisation.tsx" /* webpackChunkName: "component---src-pages-business-digitalisation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-smart-home-tsx": () => import("./../../../src/pages/smart-home.tsx" /* webpackChunkName: "component---src-pages-smart-home-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

